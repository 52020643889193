import LastScreen from './pages/lastScreen/LastScreen';
import Navbar from './components/navbar/Navbar';
import PrivateRoutes from './components/privateRoutes/PrivateRoutes';
import Questions from './pages/questions/Questions';
import HomePage from './pages/HomePage';
import LoginPage from './pages/Login';
import KioskProvider from './store/Context';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div>
      <BrowserRouter>
        <KioskProvider>
          <Navbar />
          <Routes>
            <Route path='/'>
              <Route path='/login' element={<LoginPage />} />
            </Route>
            <Route path='/' element={<PrivateRoutes />}>
              <Route index element={<HomePage />} />
              <Route path='/questions' element={<Questions />} />
              <Route path='/feedback' element={<LastScreen />} />
            </Route>
          </Routes>
        </KioskProvider>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
