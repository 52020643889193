import { useIdleTimer } from 'react-idle-timer';
import { Navigate, Outlet } from 'react-router-dom';
import { useKioskContext } from '../../store/Context';

const PrivateRoutes = () => {
  const isAuthenticated = localStorage.getItem('token');
  const { restart } = useKioskContext();

  useIdleTimer({ timeout: 20000, onIdle: restart });

  if (!isAuthenticated) {
    return <Navigate replace to={'/login'} />;
  }
  return <Outlet />;
};

export default PrivateRoutes;
