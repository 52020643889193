import React from 'react';
import './home.css';
import { useKioskContext } from '../../store/Context';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { drugTestCheckIn, getQuestions } from '../../Api/API';
import Loader from '../loader/Loader';

const Home = () => {
  const { setCurrentScreen, restart, text } = useKioskContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const showDrugTestCheckIn = localStorage.getItem('showDrugTest');

  const tempName = localStorage.getItem('participantName');

  setCurrentScreen(3);

  const handleClick = async (type) => {
    if (type === 'report') {
      setCurrentScreen(4);
      navigate('/questions');
    }
    if (type === 'drugtest') {
      setLoading(true);
      const { data, error } = await drugTestCheckIn();
      if (error) {
        setLoading(false);
        toast.error(error, {
          toastId: 'error',
        });
        return;
      }
      setLoading(false);
      setCurrentScreen(5);
      navigate('/feedback');
      return;
    }
  };

  return (
    <div className='home-container'>
      {loading && <Loader />}
      <div>
        <h1 className='heading'>
          {text.homeText} {tempName}
        </h1>
      </div>
      <div className='home-btn-container'>
        <button onClick={() => handleClick('report')} className='home-btn'>
          {text.repostBtn}
        </button>
        {(showDrugTestCheckIn === true || showDrugTestCheckIn === 'true') && (
          <button onClick={() => handleClick('drugtest')} className='home-btn'>
            {text.drugTest}
          </button>
        )}
        <button className='restart-btn' onClick={restart}>
          {text.restart}
        </button>
      </div>
    </div>
  );
};

export default Home;
