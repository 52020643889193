import React, { memo, useEffect, useState } from 'react';
import './questions.css';
import { useKioskContext } from '../../store/Context';
import { useNavigate } from 'react-router-dom';
import { getQuestions, sendQuestionAnswers } from '../../Api/API';
import { toast } from 'react-toastify';
import Loader from '../../components/loader/Loader';

const Questions = memo(() => {
  const navigate = useNavigate();
  const { setCurrentScreen, restart, text } = useKioskContext();
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [userDetails, setUserDetails] = useState({
    name: '',
    dob: '',
  });
  const [language, setLanguage] = useState('0');

  useEffect(() => {
    getQuestionsFunc();
    const name = localStorage.getItem('participantName');
    const offenderId = localStorage.getItem('offenderId');
    const dob = localStorage.getItem('dob');
    const tempDOB = dob.split('-');
    const mm = tempDOB[1];
    const yyyy = tempDOB[0];
    const dd = tempDOB[2];
    const date = `${mm}/${dd}/${yyyy}`;
    setUserDetails({
      name: name + ' (#' + offenderId + ')',
      dob: date,
    });
  }, []);

  const getQuestionsFunc = async () => {
    setLanguage(localStorage.getItem('language'));
    const { data, error } = await getQuestions();
    if (error) {
      setLoading(false);
      toast.error(error, {
        toastId: 'error',
      });
      return;
    }
    setLoading(false);
    setQuestions(data);
  };

  useEffect(() => {
    if (loading) {
      window.scrollTo(0, 0);
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = 'auto';
    }
  }, [loading]);

  const handleClick = async (type) => {
    if (type === 'back') {
      navigate('/');
      return;
    }
    if (type === 'checkIn') {
      if (Object.keys(answers).length / 2 !== questions.length) {
        toast.error(text.answerAll, {
          toastId: 'error',
        });
        return;
      }
      setLoading(true);
      const { error } = await sendQuestionAnswers(answers);
      if (error) {
        toast.error(error, {
          toastId: 'error',
        });
        return;
      }
      navigate('/feedback');
      setCurrentScreen(4);
      setLoading(false);
      return;
    }
  };

  const renderTop = () => (
    <div className='details-container'>
      <div className='name'>
        <span
          style={{
            width: language == '1' ? '250px' : '200px',
          }}
        >
          {text.name} :{' '}
        </span>
        <span>{userDetails.name}</span>
      </div>
      <div className='information'>
        <span
          style={{
            width: language == '1' ? '250px' : '200px',
          }}
        >
          {text.dob} :{' '}
        </span>
        <span>{userDetails.dob !== 'null' ? userDetails?.dob : '-'}</span>
      </div>
    </div>
  );

  const answerHandler = (quesionId, index, answer) => {
    setAnswers({
      ...answers,
      ['questionId' + (index + 1)]: quesionId,
      ['answer' + (index + 1)]: answer,
    });
  };

  const checkIsActive = (index) => {
    const data = answers['answer' + (index + 1)];
    return data;
  };

  const renderQuestions = () => (
    <div className='questions-container'>
      {questions.map((question, index) => (
        <div key={question.questionId}>
          <div className='question' key={question.id}>
            <p
              style={{
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              {language === '0'
                ? question?.question
                : question?.questionSpanish}
            </p>
            <div className='button-container'>
              <button
                style={{
                  background:
                    checkIsActive(index) === 'yes' ? 'black' : 'transparent',
                  color: checkIsActive(index) === 'yes' ? 'white' : 'black',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
                onClick={() => answerHandler(question.questionId, index, 'yes')}
              >
                {text.yes}
              </button>
              <button
                style={{
                  background:
                    checkIsActive(index) === 'no' ? 'black' : 'transparent',
                  color: checkIsActive(index) === 'no' ? 'white' : 'black',
                }}
                onClick={() => answerHandler(question.questionId, index, 'no')}
              >
                {text.no}
              </button>
            </div>
          </div>
          <div className='divider'></div>
        </div>
      ))}
    </div>
  );

  const renderButton = () => (
    <div className='bottom-button-container'>
      <button onClick={() => handleClick('back')} className='hollow'>
        {text.back}
      </button>
      <button className='hollow' onClick={restart}>
        {text.restart}
      </button>
      <button onClick={() => handleClick('checkIn')}>{text.checkIn}</button>
    </div>
  );

  return (
    <div>
      {loading && <Loader />}
      <div className='questions'>
        {renderTop()}
        <p className='heading'>{text?.questionsPageTitle}</p>
        {renderQuestions()}
        {renderButton()}
      </div>
    </div>
  );
});

export default Questions;
