import axios from 'axios';

const Baseurl = process.env.REACT_APP_API_BASE_URL;

let domainName = (url) =>
  url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
let domailURL = window.location.href;
let pathname = domainName(domailURL);

const errorHandler = (error) => {
  const language = localStorage.getItem('language');
  if (error?.response?.status === 401) {
    return {
      data: null,
      error: language === '1' ? 'Error de validacion.' : 'Validation Error.',
    };
  }
  return {
    data: null,
    error: language === '1' ? 'Algo salió mal.' : 'Something went wrong.',
  };
};

export const getToken = async (offenderId) => {
  try {
    const response = await axios.get(
      `${Baseurl}/Kiosk/getToken?offenderNumber=${offenderId}`,
      {
        headers: {
          Domain: `${pathname}`,
        },
      }
    );
    const data = await response.data;
    return { data: data, error: null };
  } catch (err) {
    return errorHandler(err);
  }
};

export const validateParticipant = async (dob, participantId, token) => {
  const language = localStorage.getItem('language');

  try {
    const response = await axios.post(
      `${Baseurl}/Kiosk/ValidateParticipant`,
      {
        birthdate: dob,
        participantId: participantId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Domain: `${pathname}`,
        },
      }
    );
    const data = response.data;
    if (!data.success) {
      return {
        data: null,
        error:
          language === '1'
            ? 'Fecha de nacimiento invalida.'
            : 'Invalid Date of Birth.',
      };
    }
    return { data: data, error: null };
  } catch (err) {
    return errorHandler(err);
  }
};

export const getQuestions = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${Baseurl}/Kiosk/GetAllQuestions`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Domain: `${pathname}`,
      },
    });
    const data = response.data;
    return { data: data, error: null };
  } catch (err) {
    return errorHandler(err);
  }
};

export const sendQuestionAnswers = async (questionAnswers) => {
  const language = localStorage.getItem('language');
  const token = localStorage.getItem('token');
  const sessionId = localStorage.getItem('sessionId');
  const personId = localStorage.getItem('personId');
  try {
    const response = await axios.post(
      `${Baseurl}/Kiosk/CheckIn`,
      {
        sessionId,
        personId,
        ...questionAnswers,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Domain: `${pathname}`,
        },
      }
    );
    const data = response.data;
    if (!data?.success) {
      return {
        data: null,
        error: language === '1' ? 'Algo salió mal.' : 'Something went wrong.',
      };
    }
    return { data: data, error: null };
  } catch (err) {
    return errorHandler(err);
  }
};

export const drugTestCheckIn = async () => {
  const language = localStorage.getItem('language');
  const token = localStorage.getItem('token');
  const sessionId = localStorage.getItem('sessionId');
  const personId = localStorage.getItem('personId');
  try {
    const response = await axios.post(
      `${Baseurl}/Kiosk/DrugTestCheckIn`,
      {
        sessionId,
        personId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Domain: `${pathname}`,
        },
      }
    );
    const data = response.data;
    if (!data?.success) {
      return {
        data: null,
        error: language === '1' ? 'Algo salió mal.' : 'Something went wrong.',
      };
    }
    return { data: data, error: null };
  } catch (error) {
    return errorHandler(error);
  }
};
