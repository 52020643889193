import React, { useEffect } from 'react';
import './loader.style.css';
import { ReactComponent as LoaderSvg } from '../../assets/svg/loader.svg';

const Loader = () => {
  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';
    window.scrollTo(0, 0);

    return () => {
      document.querySelector('body').style.overflow = 'auto';
    };
  }, []);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        background: '#fff',
        opacity: 0.7,
        position: 'absolute',
        top: 0,
        zIndex: 99999,
      }}
    >
      <LoaderSvg className='loader' />
    </div>
  );
};

export default Loader;
