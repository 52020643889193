import React from 'react';
import { useKioskContext } from '../../store/Context';
import './numpad.css';
import { toast } from 'react-toastify';

const RenderBottomButton = ({ handleClick, text }) => {
  return (
    <div
      className={'row bottom-row'}
      style={{
        width: '727px',
        margin: '0 auto',
        marginTop: '53px',
      }}
    >
      <div
        onClick={() => handleClick('back')}
        className={'keyPadMedium hollow'}
      >
        {text.back}
      </div>
      <div
        onClick={() => handleClick('restart')}
        className={'keyPadMedium hollow'}
      >
        {text.restart}
      </div>
      <div onClick={() => handleClick('next')} className={'keyPadMedium '}>
        {text.next}
      </div>
    </div>
  );
};

const checkDateValidation = (date) => {
  let dateParts = date.split('/');
  if (dateParts.length !== 3) {
    return false;
  }

  let month = parseInt(dateParts[0]);
  let day = parseInt(dateParts[1]);
  let year = parseInt(dateParts[2]);

  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
    ListofDays[1] = 29;
  }

  if (day < 1 || day > ListofDays[month - 1]) {
    return false;
  }

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  if (year <= 1800) {
    return false;
  }

  if (
    year > currentYear ||
    (year === currentYear && month >= currentMonth) ||
    (year === currentYear && month === currentMonth && day >= currentDay)
  ) {
    return false;
  }

  return true;
};

const NumPad = ({ value, setValue, nextBtnFunc }) => {
  const { currentScreen, setCurrentScreen, text, restart } = useKioskContext();

  const handleClick = (val) => {
    if (val === 'restart') {
      restart();
      return;
    }
    if (val === 'clear') {
      setValue('');
      return;
    }
    if (val === 'next') {
      if (value.length === 0 && currentScreen === 0) {
        toast.error(text.offenderRequired, {
          toastId: 'error',
        });
        return;
      }
      if (value.length === 0 && currentScreen === 1) {
        toast.error(text.dateOfBirthRequired, {
          toastId: 'error',
        });
        return;
      }
      if (value.length <= 13 && currentScreen === 1) {
        toast.error(text.validDOB, {
          toastId: 'error',
        });
        return;
      }
      if (currentScreen === 1) {
        const temp = value.replaceAll(' / ', '/');
        if (!checkDateValidation(temp)) {
          toast.error(text.validDOB, {
            toastId: 'error',
          });
          return;
        }
      }

      if (currentScreen === 1) {
        nextBtnFunc();
        return;
      }
      nextBtnFunc();
      return;
    }
    if (val === 'back') {
      setCurrentScreen(currentScreen - 1);
      return;
    }
    if (val === '-1') {
      setValue(value.slice(0, -1));
      if (currentScreen === 1 && value[value.length - 2] === ' ') {
        setValue(value.slice(0, -4));
      }
      return;
    }
    if (currentScreen === 1) {
      if (value.length === 2 || value.length === 7) {
        setValue((prev) => prev + ' / ');
      }
      if (value.length === 14) {
        return;
      }
    }
    setValue((prev) => prev + val);
  };

  return (
    <div className={'mainContainer'}>
      <div className={'container'}>
        <div className='row'>
          <div onClick={() => handleClick('1')} className={'keyPad'}>
            1
          </div>
          <div onClick={() => handleClick('2')} className={'keyPad'}>
            2
          </div>
          <div onClick={() => handleClick('3')} className={'keyPad'}>
            3
          </div>
        </div>
        <div className='row'>
          <div onClick={() => handleClick('4')} className={'keyPad'}>
            4
          </div>
          <div onClick={() => handleClick('5')} className={'keyPad'}>
            5
          </div>
          <div onClick={() => handleClick('6')} className={'keyPad'}>
            6
          </div>
        </div>
        <div className='row'>
          <div onClick={() => handleClick('7')} className={'keyPad'}>
            7
          </div>
          <div onClick={() => handleClick('8')} className={'keyPad'}>
            8
          </div>
          <div onClick={() => handleClick('9')} className={'keyPad'}>
            9
          </div>
        </div>
        <div className={'row'}>
          <div onClick={() => handleClick('-1')} className={'keyPadLarge'}>
            {text.backSpace}
          </div>
          <div onClick={() => handleClick('0')} className={'keyPad'}>
            0
          </div>
        </div>
        <div
          className='row' 
          style={{
            justifyContent: currentScreen === 1 ? 'center' : 'space-between',
            marginBottom: '2vh',
            marginTop: currentScreen === 1 ? '4px' : '1vh',
          }}
        >
          <div onClick={() => handleClick('clear')} className={'keyPadMedium'}>
            {text.clear}
          </div>
          {currentScreen !== 1 && (
            <div onClick={() => handleClick('next')} className={'keyPadMedium'}>
              {text.next}
            </div>
          )}
        </div>
      </div>
      {currentScreen === 1 && (
        <RenderBottomButton text={text} handleClick={handleClick} />
      )}
    </div>
  );
};

export default NumPad;
