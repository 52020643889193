import React from 'react';
import './lastscreen.css';
import { TechnicalSupportImg } from '../../assets';
import { useKioskContext } from '../../store/Context';

const LastScreen = () => {
  const { currentScreen, text, restart, closeSession } = useKioskContext();

  const screen = localStorage.getItem('currentScreen');
  return (
    <div className='lastScreen'>
      <div className='img'>
        <img src={TechnicalSupportImg} alt='' />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className='content-container'>
          {(currentScreen === 4 || (screen && JSON.parse(screen) === 4)) && (
            <span>{text.reportCheckedIn}</span>
          )}
          {(currentScreen === 5 || (screen && JSON.parse(screen) === 5)) && (
            <span>{text.drugTestText}</span>
          )}
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <button className='btn-medium' onClick={restart}>
          {text.restart}
        </button>
      </div>
    </div>
  );
};

export default LastScreen;
