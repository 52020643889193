import React, { useEffect, useState } from 'react';
import { DownBtn, UpBtn, UserLogo } from '../../assets';
import NumPad from '../numpad/NumPad';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useKioskContext } from '../../store/Context';
import './loginpage.css';
import { getToken } from '../../Api/API';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';

const Login = () => {
  const { setCurrentScreen, language, setLanguage, text, setToken } =
    useKioskContext();
  const [offenderId, setOffenderId] = useState('');
  const [showNumPad, setShowNumPad] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const clickOutside = useClickOutside(() => {
    setShowDropdown(false);
  });

  const handleClick = async () => {
    setLoading(true);
    console.log('Hhello');
    const { data, error: err } = await getToken(offenderId);
    if (err) {
      setLoading(false);
      toast.error(err, {
        toastId: 'error',
      });
      return;
    }
    if (data) {
      localStorage.setItem('offenderId', offenderId);
      setToken(data.token);
      setCurrentScreen(1);
    }
    setLoading(true);
  };

  const renderDropDown = () => (
    <div ref={clickOutside} className={'dropDownList'}>
      <div
        style={{
          top: '28%',
        }}
        className={'imgContainer'}
      >
        <img src={UpBtn} alt='' />
      </div>
      <div
        onClick={() => {
          setLanguage('0');
          localStorage.setItem('language', 0);
        }}
        className={!language ? 'activeDropDownItem' : 'dropDownItem'}
      >
        English
      </div>
      <div
        onClick={() => {
          setLanguage('1');
          localStorage.setItem('language', 1);
        }}
        className={language ? 'activeDropDownItem' : 'dropDownItem'}
      >
        Spanish
      </div>
    </div>
  );

  return (
    <div>
      {loading && <Loader />}
      <div className='login'>
        <div
          onClick={() => setShowDropdown(!showDropdown)}
          className={'dropDownContainer'}
        >
          <span className={'dropDownText'}>
            {language === '1' ? 'Spanish' : 'English'}
          </span>
          <img className={'imgContainer'} src={DownBtn} alt='' />
          {showDropdown && renderDropDown()}
        </div>
        <div className={'primaryText'}>{text.loginText}</div>

        <div className={'inputContainer'}>
          <img className={'userLogo'} src={UserLogo} alt='' />
          <input
            onClick={() => setShowNumPad(!showNumPad)}
            placeholder={text.offenderNumber}
            disabled={true}
            value={offenderId}
            className={'inputField'}
          />
        </div>
        <NumPad
          value={offenderId}
          setValue={setOffenderId}
          nextBtnFunc={handleClick}
        />
      </div>
    </div>
  );
};

export default Login;
