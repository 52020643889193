import { useContext, createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const KioskContext = createContext();

const KioskProvider = ({ children }) => {
  const navigate = useNavigate();

  const getTexts = () => {
    return {
      navTitle:
        language === '1' ? 'KIOSCO DE AUTOSERVICIO' : 'SELF SERVE KIOSK',
      loginText:
        language === '1'
          ? 'Ingrese su ID usando el teclado y presione “Siguiente” para continuar:'
          : 'Enter your ID using the keypad and press “Next” to continue:',
      offenderNumber:
        language === '1' ? 'Número de delincuente' : 'Offender Number',
      backSpace: language === '1' ? 'Retroceso' : 'Backspace',
      clear: language === '1' ? 'Clara' : 'Clear',
      next: language === '1' ? 'Próxima' : 'Next',
      reportDateText:
        language === '1'
          ? 'Ingrese su fecha de nacimiento usando el teclado y presione “Siguiente” para continuar:'
          : 'Enter your date of birth using the keypad and press “Next” to continue:',
      dateFormat: language === '1' ? 'mm / dd / aaaa' : 'mm / dd / yyyy',
      back: language === '1' ? 'Atrás' : 'Back',
      restart: language === '1' ? 'Reanudar' : 'Restart',
      homeText: language === '1' ? 'Bienvenida ' : 'Welcome ',
      repostBtn:
        language === '1' ? 'Informe Fecha Entrada' : 'Report Date Check In',
      drugTest:
        language === '1'
          ? 'Registro de prueba de drogas'
          : 'Drug Test Check In',
      name: language === '1' ? 'Nombre' : 'Name',
      dob: language === '1' ? 'Fecha de nacimiento' : 'Birth Date',
      yes: language === '1' ? 'Sí' : 'Yes',
      no: language === '1' ? 'No' : 'No',
      checkIn: language === '1' ? 'Registrarse' : 'Check In',
      drugTestText:
        language === '1'
          ? 'Complete un formulario de prueba de drogas ubicado en el portapapeles. Mantenga su forma y tome asiento. Alguien estará contigo en breve.'
          : 'Please complete a Drug Testing form located on the clipboard. Hold your form and have a seat. Someone will be with you shortly.',
      reportCheckedIn:
        language === '1'
          ? 'Ya está registrado. Tome asiento y alguien estará con usted lo antes posible.'
          : 'You are now checked in. Please have a seat and someone will be with you as soon as possible.',
      closeSession: language === '1' ? 'Cerrar la sesión' : 'Close Session',
      questionsPageTitle:
        language === '1'
          ? 'Responda todas las preguntas y luego seleccione el botón "Registrarse" para continuar.'
          : 'Please answer all the questions and then select the “Check In" button to proceed.',
      offenderRequired:
        language === '1'
          ? 'Se requiere el número de delincuente.'
          : 'Offender Number is required.',
      dateOfBirthRequired:
        language === '1'
          ? 'Se requiere fecha de nacimiento.'
          : 'Date of Birth is required.',
      validDOB:
        language === '1'
          ? 'Por favor ingrese una fecha de nacimiento válida.'
          : 'Please enter valid Date of Birth.',
      validDate:
        language === '1'
          ? 'Por favor ingrese una fecha válida.'
          : 'Please enter valid date.',
      validMonth:
        language === '1'
          ? 'Por favor ingrese el mes válido.'
          : 'Please enter valid month.',
      validYear:
        language === '1'
          ? 'Por favor ingrese el año válido.'
          : 'Please enter valid year.',
      answerAll:
        language === '1'
          ? 'Por favor responda todas las preguntas.'
          : 'Please answer all the questions.',
      somethingWentWrong:
        language === '1' ? 'Algo salió mal.' : 'Something went wrong.',
      unauthorised: language === 1 ? 'Error de validacion.' : 'Validation Error.',
    };
  };

  const [currentScreen, setCurrentScreen] = useState(0);
  const [language, setLanguage] = useState('0');
  const [text, setText] = useState(getTexts());
  const [token, setToken] = useState('');

  useEffect(() => {
    if (localStorage.getItem('language')) {
      setLanguage(localStorage.getItem('language'));
    } else {
      localStorage.setItem('language', -language);
    }
    setText(getTexts());
  }, [language]);

  useEffect(() => {
    if (!localStorage.getItem('currentScreen')) {
      localStorage.setItem('currentScreen', currentScreen);
    }
    if (currentScreen != '0') {
      localStorage.setItem('currentScreen', currentScreen);
    }
  }, [currentScreen]);

  const closeSession = () => {
    localStorage.removeItem('currentScreen');
    localStorage.removeItem('email');
    localStorage.removeItem('offenderId');
    localStorage.removeItem('participantName');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('personId');
    localStorage.removeItem('token');
    setCurrentScreen(0);
    navigate('/login');
  };

  const restart = () => {
    localStorage.clear();
    localStorage.setItem('language', '0');
    setLanguage('0');
    setCurrentScreen(0);
    navigate('/login');
  };

  return (
    <KioskContext.Provider
      value={{
        currentScreen,
        setCurrentScreen,
        language,
        setLanguage,
        restart,
        text,
        token,
        setToken,
        closeSession,
      }}
    >
      {children}
    </KioskContext.Provider>
  );
};

export default KioskProvider;

export const useKioskContext = () => {
  return useContext(KioskContext);
};
