import React from 'react';
import { useKioskContext } from '../store/Context';
import Login from '../components/login/Login';
import ReportDate from '../components/reportdate/ReportDate';
import { Navigate } from 'react-router-dom';

const LoginPage = () => {
  const { currentScreen } = useKioskContext();

  const isAuthenticated = localStorage.getItem('token');
 

  if (isAuthenticated) {
    return <Navigate replace to={'/'} />;
  }


  return (
    <>
      {currentScreen === 0 && <Login />}
      {currentScreen === 1 && <ReportDate />}
    </>
  );
};

export default LoginPage;
