import React from 'react';
import { CpKioskLogo, SpaldingKioskLogo } from '../../assets';
import { useKioskContext } from '../../store/Context';

const Navbar = () => {
  const { language } = useKioskContext();
  let domainName = (url) =>
    url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
  let domailURL = window.location.href;
  let pathname = domainName(domailURL);

  const renderLogo = () => {
    if (pathname === 'cpskiosk' || pathname === 'cpskiosk2') {
      return (
        <img
          style={{
            position: 'absolute',
            left: 0,
          }}
          src={CpKioskLogo}
          alt='CPS'
        />
      );
    }

    if (pathname === 'spaldingkiosk' || pathname === 'spaldingkiosk2') {
      return (
        <div className='spaldingkiosk'>
          <img src={SpaldingKioskLogo} alt='Spalding' />
        </div>
      );
    }
  };

  return (
    <div>
      <nav
        style={{
          height: '100px',
          background:
            'linear-gradient(200deg, #b7b7b7 5.92%, #454545f7 60.54%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '33px',
          fontWeight: 600,
          position: 'relative',
        }}
      >
        {renderLogo()}
        {language === '0' && 'SELF SERVE KIOSK'}
        {language === '1' && 'KIOSCO DE AUTOSERVICIO'}
      </nav>
    </div>
  );
};

export default Navbar;
