import React, { useState } from 'react';
import NumPad from '../numpad/NumPad';
import './reportdate.css';
import { useKioskContext } from '../../store/Context';
import { validateParticipant } from '../../Api/API';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';

const ReportDate = () => {
  const { text, token, setCurrentScreen } = useKioskContext();
  const [date, setDate] = useState('');
  const [loading, setLoading] = useState(false);

  const handleNextClick = async () => {
    setLoading(true);
    const tempOffenderId = localStorage.getItem('offenderId');
    const offenderId = tempOffenderId;
    const tempDate =
      date.split(' / ')[2] +
      '-' +
      date.split(' / ')[0] +
      '-' +
      date.split(' / ')[1];

    const { data, error } = await validateParticipant(
      tempDate,
      offenderId,
      token
    );
    if (error) {
      toast.error(error, {
        toastId: 'error',
      });
      setLoading(false);
      return;
    }

    localStorage.setItem('personId', data?.personId);
    localStorage.setItem('sessionId', data?.sessionId);
    localStorage.setItem('participantName', data?.participantName);
    localStorage.setItem('email', data?.emailAddress);
    localStorage.setItem('token', token);
    localStorage.setItem('dob', tempDate);
    localStorage.setItem('showDrugTest', data?.drugTestCheck || 'false');

    setLoading(false);
    setCurrentScreen(3);
  };

  return (
    <div>
      {loading && <Loader />}
      <div className={'primaryText'}>{text.reportDateText}</div>
      <div className={'inputContainer'}>
        <input
          value={date}
          placeholder={text.dateFormat}
          disabled={true}
          className={'reportInputField'}
        />
      </div>
      <NumPad nextBtnFunc={handleNextClick} value={date} setValue={setDate} />
    </div>
  );
};

export default ReportDate;
